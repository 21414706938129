<template>
  <div class="root">
    <div class="bienvenue text-center">
      <div class="title">
        <h1 class="main_title">{{ lang.bienvenue }}</h1>
        <h2 v-html="lang.bienvenue2"></h2>
      </div>

      <div class="bottom-bar">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <router-link
                to="/comment-ca-marche"
                class="btn btn-primary d-block"
                >{{ lang.commencer }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--
  <table class="table border">
    <tr v-for="(item, i) in this.$store.state.languages[0]" :key="i">
      <td>{{i}}</td>
      <td>{{item}}</td>
    </tr>
  </table>
  --></div>
</template>

<style lang="scss">
.bienvenue {
  width: 100%;
  height: 100%;
  background: url("../assets/illustration.svg") center center no-repeat;
  //min-height: 780px;
}
.bottom-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: 0 auto;
  padding: 0;

  .container {
    padding-left: 3px;
    padding-right: 3px;

    .row {
      &:first-child {
        box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.04);
      }
      background: #f7f7f7;

      padding: 5px 0px;
      margin: 0 2px;
    }
    .row:last-child {
      border-bottom: 5px solid #fff;
    }
  }
  .btn {
    color: #fff;
    margin: 0 auto;
    max-width: 400px;
    height: 100%;
  }
}
</style>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Bienvenue",
  components: {
    //HelloWorld
  },
  computed: {
    lang() {
      let langIndex = this.$store.state.lang;
      return this.$store.state.languages[langIndex];
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
