import { createRouter, createWebHistory } from "vue-router";
import Bienvenue from "../views/Bienvenue.vue";

const routes = [
    {
        path: "/",
        name: "Bienvenue",
        component: Bienvenue,
        meta: { transitionName: 'fade' },
    },
    {
        path: "/a-propos",
        name: "A propos",
        meta: { transitionName: 'fade' },
        component: () =>
            import(/* webpackChunkName: "apropos" */ "../views/Apropos.vue"),
    },
    {
        path: "/mentions-legales",
        name: "Mentions légales",
        meta: { transitionName: 'fade' },
        component: () =>
            import(/* webpackChunkName: "legal" */ "../views/legal.vue"),
    },
    {
        path: "/comment-ca-marche",
        name: "Comment ca marche ?",
        meta: { transitionName: 'fade' },
        component: () =>
            import(/* webpackChunkName: "intro" */ "../views/intro.vue"),
    },
    {
        path: "/critere1",
        name: "Critere 1",
        meta: { transitionName: 'slide', pos : 1 },
        component: () =>
            import(/* webpackChunkName: "critere1" */ "../views/critere1.vue"),
    },
    {
        path: "/critere2",
        name: "Critere 2",
        meta: { transitionName: 'slide', pos : 2 },
        component: () =>
            import(/* webpackChunkName: "critere2" */ "../views/critere2.vue"),
    },
    {
        path: "/critere3",
        name: "Critere 3",
        meta: { transitionName: 'slide', pos : 3 },
        component: () => import(/* webpackChunkName: "critere3" */ "../views/critere3.vue"),
    },
    {
        path: "/critere4",
        name: "Critere 4",
        meta: { transitionName: 'slide', pos : 4 },
        component: () =>
            import(/* webpackChunkName: "critere4" */ "../views/critere4.vue"),
    },
    {
        path: "/critere5",
        name: "Critere 5",
        meta: { transitionName: 'slide', pos : 5 },
        component: () =>
            import(/* webpackChunkName: "critere5" */ "../views/critere5.vue"),
    }
    ,
    {
        path: "/coordonnees",
        name: "Coordonnées",
        meta: { transitionName: 'slide', pos : 6 },
        component: () =>
            import(/* webpackChunkName: "coord" */ "../views/coordonnees.vue"),
    }
    ,
    {
        path: "/resultats",
        name: "Résultats",
        meta: { transitionName: 'slide', pos : 7 },
        component: () =>
            import(/* webpackChunkName: "resultats" */ "../views/resultats.vue"),
    },
    {
        path: "/nous-contacter",
        name: "Nous contacter",
        meta: { transitionName: 'fade', pos : 7 },
        component: () =>
            import(/* webpackChunkName: "contact" */ "../views/contact.vue"),
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
