<template>
  <nav class="navbar fixed-top">
    <div class="container">
      <router-link to="/" class="logo">
        <img src="../src/assets/logo.svg" alt="" />
      </router-link>

      <div class="navigations-buttons d-flex align-items-baseline">
        <div class="pe-4">
          <div class="btn-group" id="lang_selector">
            <button
              class="btn btn-sm dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                :src="require('./assets/' + lang.id +'.svg')"
                alt=""
                class="flag"
              />
              {{ lang.id }}
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
              <li
                v-for="(item, index) in this.$store.state.languages"
                v-bind:key="index"
              >
                <a
                  class="dropdown-item text-end"
                  href="#"
                  @click="changeLang(index)"
                  >{{ item.label }}
                  <img
                    :src="require('./assets/' + item.id +'.svg')"
                    alt=""
                    class="flag" 
                /></a>
              </li>
            </ul>
          </div>
        </div>
        <a href="#" @click="toggleMenu"
          ><img src="../src/assets/burger_menu.svg"
        /></a>
      </div>
    </div>
  </nav>

  <div class="menu" v-bind:class="{ opened: open }">
    <div class="modalmenu" @click="toggleMenu"></div>
    <div class="menu_list" @click="toggleMenu">
      <ul>
        <li>
          <router-link to="/">{{ lang.accueil }}</router-link>
        </li>
        <li>
          <router-link to="/a-propos">{{ lang.apropos_title }}</router-link>
        </li>
        <li>
          <router-link to="/nous-contacter">{{
            lang.contact_title
          }}</router-link>
        </li>
        <li>
          <a
            href="https://www.echanges-fga-cfdt.fr/fcrm-form/production-agricole_cuma_etarf/"
            target="_blank"
            >{{ lang.enquete_title }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-box-arrow-up-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
              />
              <path
                fill-rule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
              /></svg
          ></a>
        </li>
        <li>
          <a
            href="https://fga.cfdt.fr/upload/docs/application/pdf/2020-11/2020_11_20_guide_pedagogique_paritaire_classification_ccn.pdf"
            target="blank"
            >{{ lang.guide_title }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-box-arrow-up-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
              />
              <path
                fill-rule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://fga.cfdt.fr/portail/agroalimentaire/nos-outils/production-agricole-srv1_1146944"
            target="blank"
            >{{ lang.prod_agri_fga_title }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-box-arrow-up-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
              />
              <path
                fill-rule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
              />
            </svg>
          </a>
        </li>
        <li>
          <router-link to="/mentions-legales">{{
            lang.legal_title
          }}</router-link>
        </li>

        <li class="text-center my-4">
          {{ lang.faites_connaitre }}<br />
          <img
            src="../src/assets/Salaire-FGA-CFDT_qrcode.svg"
            width="150"
            height="150"
          /><br />
          <small class="text-muted">{{ lang.scannez }}</small>
        </li>
      </ul>
    </div>
  </div>

  <!--<router-view />-->

  <router-view v-slot="{ Component }">
    <transition :name="transitionName" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
const DEFAULT_TRANSITION = "fade";
export default {
  name: "App",
  data() {
    return {
      open: false,
      prevHeight: 0,
      transitionName: DEFAULT_TRANSITION,
    };
  },
  methods: {
    toggleMenu() {
      this.open = !this.open;
    },
    changeLang(lang) {
      this.$store.state.lang = lang;
    },
    stopGoogleAnalytics() {
      this.$ga.disable();
    },
    engageGoogleAnalytics() {
      this.$ga.enable();
    },
  },
  computed: {
    lang() {
      let langIndex = this.$store.state.lang;
      return this.$store.state.languages[langIndex];
    },
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;

      if (transitionName === "slide") {
        const toDepth = to.meta.pos;
        const fromDepth = from.meta.pos;
        transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  },
  components: {
    //CookieLaw,
  },
  /*props: {
    msg: String
  }*/
};
</script>


<style lang="scss">
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.15s ease-out;
}

.slide-left-enter-from,
.slide-right-leave-to {
  transform: translate(100%, 0);
}
.slide-left-leave-to,
.slide-right-enter-from {
  transform: translate(-100%, 0);
}
.slide-left-enter-to,
.slide-right-enter-to {
  transform: translate(0, 0);
}
.slide-left-leave-from,
.slide-right-leave-from {
  transform: translate(0%, 0);
}
////////////////////////////////////////
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
.root {
  display: flex;
  width: 100%;
}
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap");
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/vue-next-select/dist/index.min.css";

$primary: #e75c21;
$body-bg: #f7f7f7;
$body-color: #30353a;
$secondary: #30353a;
$font-family-sans-serif: "Quicksand", sans-serif;
$headings-font-weight: 600;
$btn-padding-y: 15px;
$btn-padding-x: 15px;
$btn-link-color: #fff;
$light: #d5d5d5;
$font-size-base: 0.8rem;
$table-striped-bg: #fff;
$table-cell-padding-x: 0.75rem;
$table-cell-padding-y: 0.75rem;
// Required
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/modal";
/*
  @import "../node_modules/bootstrap/scss/nav";
  @import "../node_modules/bootstrap/scss/navbar";
  @import "../node_modules/bootstrap/scss/card";
  @import "../node_modules/bootstrap/scss/accordion";
  @import "../node_modules/bootstrap/scss/breadcrumb";
  @import "../node_modules/bootstrap/scss/pagination";
  @import "../node_modules/bootstrap/scss/badge";
  @import "../node_modules/bootstrap/scss/alert";
  @import "../node_modules/bootstrap/scss/progress";
  @import "../node_modules/bootstrap/scss/list-group";
	@import "../node_modules/bootstrap/scss/popover";
  @import "../node_modules/bootstrap/scss/close";
  @import "../node_modules/bootstrap/scss/toasts";
  
  @import "../node_modules/bootstrap/scss/tooltip";
  @import "../node_modules/bootstrap/scss/popover";
  @import "../node_modules/bootstrap/scss/carousel";
  @import "../node_modules/bootstrap/scss/spinners";
  @import "../node_modules/bootstrap/scss/offcanvas";
  */

// Helpers & Utilities
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/utilities/api";

body,
html {
  height: 100%;
}
.main_title {
  margin-top: 30px;
}
h3 {
  font-size: 24px; //calc(0.6vw + 14px);
}
.subtitle {
  font-size: calc(0.4vw + 15px);
}
.flag {
  width: 15px;
  margin-bottom: 3px;
  margin-right: 4px;
}
li .flag {
  margin-left:5px;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 5px solid #fff;
  border-bottom: 0;
  padding: 67px 15px 0 15px;
  padding-top: 67px;
  overflow: hidden;
  min-height: 100%;
  position: relative;
  display: flex;

  .vue-select {
    font-weight: 600;
    width: 100%;
    display: block;
    border: none;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);

    .vue-input {
      > input {
        color: $body-color;
        font-weight: 600;
        font-size: $font-size-base;
        cursor: pointer;
      }
      input::placeholder {
        color: $body-color;
        opacity: 1;
      }
    }
    .vue-dropdown {
      margin-top: 25px;
      border: none;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      left: 0;
      li {
        padding: 10px;
        text-align: left;
      }
      .highlighted {
        background: $primary;
        color: #fff;
      }
    }
  }
}
.form-control,
.form-select {
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  border: none;
  padding: 15px;
}
.navbar {
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }
}
#lang_selector button {
  text-transform: uppercase;
  font-weight: 600;
}
.menu {
  z-index: 1031;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity ease 0.3s;
  .modalmenu {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }
  &.opened {
    transition: all ease 0.3s;
    visibility: visible;
    opacity: 1;
    .menu_list {
      transform: translate(0%, 0);
    }
  }
  .menu_list {
    background: #fff;
    position: absolute;
    top: 0;
    width: 25%;
    min-width: 300px;
    bottom: 0;
    right: 0;
    padding: 30px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
    transform: translate(100%, 0);
    transition: all ease 0.3s;
    overflow-y: auto;
    ul {
      list-style-type: none;
      padding: 0;
      li {
        padding: 15px 0;
        border-bottom: 1px solid #d8d8d8;
        a {
          text-decoration: none;
          svg {
            float: right;
            margin-top: 3px;
            margin-right: 10px;
            color: #999;
          }
        }
      }
    }
  }
  .router-link-active {
    font-weight: bold;
    color: #707070;
  }
}
.critere_container {
  flex: 1;
  //margin-bottom: 200px;
}
.stepper {
  display: flex;
  align-items: center;
  justify-content: center;
  .step_marker {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: #929292;
    margin: 10px;

    opacity: 0.3;
    &.current {
      opacity: 1;
    }
  }
}
.btn {
  font-weight: 600;
}
.step {
  margin-top: 30px;
  margin-bottom: 180px;
}
.feuille {
  width: 70px;
  position: absolute;
  top: 60px;
  right: 0px;
  animation: wind 5s infinite alternate-reverse;
  transform-origin: top center;
  opacity: 0.4;
}
.form-check {
  padding: 10px 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(5px);
  --webkit-backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.9);
  margin: 10px 0;
  display: flex;
  align-items: center;
  text-align: left;
  transition: all 0.3s ease;
  .form-check-input {
    flex-shrink: 0;
    margin: 10px 20px 10px 10px;
  }
  &:hover,
  &.current {
    background-color: #fee4d9;
  }
}
.red,
.green,
.blue {
  font-weight: bold;
}
.red {
  color: #e75c21;
}
.green {
  color: #8ead46;
}
.blue {
  color: #4a60db;
}
@keyframes wind {
  to {
    transform: rotate(20deg);
  }
}
.encart_res {
  background-color: $primary;
  color: #fff;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
  margin: 0 -5px 15px -5px;
  h3 {
    font-size: 16px;
  }
  .res {
    font-size: 40px;
    font-weight: bold;
    margin: 5px 0;
    &.small {
      font-size: 26px;
    }
    small {
      font-size: 0.4em;
    }
  }
}
.encart_savoir {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
  padding: 15px;
  margin: 15px 0 30px 0;
}

.table {
  td,
  th {
    border: none;
  }
  th {
    font-weight: 300;
    font-size: 11px;
    border-bottom: 0;
  }
  tbody {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
    tr:first-child {
      td:first-child {
        border-top-left-radius: 10px;
      }
      td:last-child {
        border-top-right-radius: 10px;
      }
    }
  }
  tfoot {
    td {
      background: #dedede;
      font-weight: bold;
    }
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }
}
</style>
