import {
  createStore
} from "vuex";
import departements from './departements-region.json';

export default createStore({
  state: {
    lang: 0,
    languages: [{
        id: "fr",
        label: "Français",
        accueil: "Accueil",
        bienvenue: "Bienvenue",
        bienvenue2: "sur <em>Mon salaire agricole</em><br/><br/>L'outil CFDT Agri-Agro<br />pour classifier votre emploi en production agricole",
        commencer: "Commencer !",
        apropos_title: "Qui sommes-nous",
        legal_title: "Mentions légales",
        cgu_title: "Politique de confidentialité",
        intro_title: "Comment ça marche ?",
        intro_subtitle: "Vous êtes sur le point de commencer l'estimation de votre poste.<br/><strong>C'est une bonne nouvelle !</strong>",
        intro_etape: "Suivez les 3 étapes rapides de l'application pour obtenir votre classification.",
        intro_etape1: "Evaluez l'ensemble des 5 critères de classement",
        intro_etape2: "L'application vous restitue votre classification et votre rémunération horaire minimum",
        intro_etape3: "Renseignez vos coordonnées pour recevoir votre résultat personnalisé immédiatement !",
        valid_intro: "Démarrer votre classification",
        critere: "Critère",
        critere_subtitle: "Evaluez le critère",
        critere1_name: "Technicité du poste",
        critere2_name: "Autonomie du poste",
        critere3_name: "Responsabilité du poste",
        critere4_name: "Management du poste",
        critere5_name: "Relationnel du poste",
        precedent: "Etape précédente",
        suivant: "Etape suivante",
        c1l1: '<span class="red">Exécution </span><span class="green">d’une ou plusieurs tâches </span><span class="blue">simples</span>, nécessitant peu ou pas d’expérience',
        c1l2: '<span class="red">Réalisation d’opérations</span> <span class="blue">qualifiées</span>, <span class="green">variées</span>, nécessitant une expérience antérieure ou un temps d’appropriation',
        c1l3: '<span class="red">Réalisation d’activités</span> <span class="blue">très qualifiées</span>, <span class="green">fortement évolutives</span>, nécessitant une maîtrise confirmée du métier',
        c1l4: '<span class="red">Capacité de conduite</span> <span class="green">de l’ensemble des activités</span>, <span class="blue">complexes</span> <span class="green">d’un domaine de spécialités</span>, en nécessitant une maîtrise complète',
        c1l5: '<span class="red">Responsabilité de l’ensemble des missions </span> <span class="blue">y compris expertes</span> <span class="green">d’un ou plusieurs domaines de spécialités</span>',

        c2l1: 'Application de <span class="green">consignes précises</span> ou de procédures connues, sous surveillance et <span class="red">contrôle fréquents</span>',
        c2l2: 'Activités définies par des <span class="green">instructions générales</span>, sous surveillance et <span class="red">contrôle intermittents ou périodiques</span>',
        c2l3: 'Adaptation des méthodes dans le cadre d’objectifs définis ou à partir de <span class="green">directives générales</span>, contrôle ou appréciation <span class="red">à posteriori des résultats ou de l’atteinte des objectifs</span>',
        c2l4: 'Définition de stratégies par <span class="green">délégation directe</span> , évaluation à moyen ou long terme des <span class="red">résultats globaux</span> de son périmètre de responsabilité',

        c3_group1: 'Respect des normes',

        c31l1: 'Exigences normatives <span class="red">faibles ou simples</span> et conséquences <span class="green">limitées</span> en cas de non respect',
        c31l2: 'Exigences normatives <span class="red">fortes ou complexes</span> et conséquences <span class="green">importantes</span> en cas de non respect',
        c31l3: '<span class="red">Responsabilité du déploiement</span> des normes et de la <span class="green">vérification de leur respect</span>',

        c3_group2: 'Enjeux économiques',

        c32l1: 'Contribution <span class="red">limitée</span> aux objectifs de l’activité, obtenue notamment par le <span class="green">respect des contraintes de productivité et de qualité</span>',
        c32l2: 'Contribution <span class="red">moyenne</span> au résultat de l’entreprise notamment par <span class="green">l’optimisation des moyens, de l’organisation de son emploi/ de son équipe</span>',
        c32l3: 'Contribution <span class="red">essentielle</span> au développement de l’entreprise notamment <span class="green">par orientation de ses activités et identification d’innovations</span>',

        c4l1: 'Transmission de savoir faire possible et/ou rappeler une consigne. <span class="red">Pas de responsabilité particulière d’animation</span>',
        c4l2: '<span class="red">Animation technique et/ou organisationnelle</span> régulière d’une ou plusieurs équipes, <span class="blue">majoritairement temporaires/saisonniers</span>',
        c4l3: '<span class="red">Animation technique et/ou organisationnelle</span> d’une ou plusieurs équipes, <span class="blue">majoritairement permanente</span>',
        c4l4: '<span class="red">Encadrement hiérarchique de proximité</span> (d’une ou plusieurs <span class="blue">équipes d’exécution</span>)',
        c4l5: '<span class="red">Encadrement hiérarchique</span> <span class="blue">d’encadrants ou de cadres</span>',
        c4l6: '<span class="red">Responsabilité hiérarchique</span> <span class="blue">de tous les collaborateurs</span> de l’entreprise ou de l’établissement',

        c5l1: 'Echanges professionnels <span class="red">simples</span>, nécessitant de bien comprendre ses Interlocuteurs internes ou externes et <span class="blue">de se faire comprendre</span> par eux',
        c5l2: 'Echanges professionnels <span class="red">courants</span>, nécessitant <span class="blue">une écoute attentive</span> de ses interlocuteurs, <span class="blue">la reformulation</span> de leurs demandes, une force de conviction et <span class="blue">l’apport de conseils</span>',
        c5l3: 'Echanges professionnels <span class="red">complexes</span>, nécessitant <span class="blue">la construction d’argumentaires</span> et d’explications pédagogiques, <span class="blue">la capacité à mobiliser</span> ses interlocuteurs externes ou internes',
        c5l4: 'Relationnel professionnel <span class="red">déterminant</span> pour l’entreprise, nécessitant persuasion et diplomatie, <span class="blue">conduite de négociations et représentation de l’entreprise</span> auprès d’acteurs privés ou institutionnels',

        coordonnees_title: 'Vous y êtes presque...',
        coordonnees_subtitle: 'Pour recevoir votre résultat,<br/>renseignez vos coordonnées',
        voir_resultats: 'Voir les résultats',
        placeholder_nom: 'Votre nom',
        placeholder_prenom: 'Votre prénom',
        placeholder_email: 'Votre adresse e-mail',
        placeholder_departement: 'Sélectionnez votre département',
        rechercher: 'Rechercher...',
        contact: "Je souhaite être recontacté par la FGA-CFDT pour être conseillé sur mon poste",
        placeholder_tel: "Votre téléphone",
        rgpd: 'En cochant la case ci-contre, vous acceptez que vos données personnelles renseignées ci-dessus soient traitées par nos services en accord avec notre <a href="https://www.echanges-fga-cfdt.fr/politique-de-confidentialite-2/" target="_blank">politique de confidentialité</a>. La FGA-CFDT met en œuvre des traitements sur vos données personnelles afin de pouvoir vous aider en traitant et suivant votre demande. Vous disposez de différents droits sur vos données, tels qu\'un droit de portabilité, d\'accès, de rectification, d\'effacement, de limitation, d\'opposition et de retrait de votre consentement. Vous pouvez exercer ces droits en adressant votre demande via <a href="https://www.echanges-fga-cfdt.fr/fcrm-form/rgpd-faire-valoir-vos-droits-sur-vos-donnees-personnelles/" target="_blank">notre formulaire RGPD.</a>',
        resultats_title: 'Voici vos résultats !',
        resultats_subtitle: 'Nous vous les avons aussi envoyé par e-mail',
        default_statut: 'Employé',
        statut_technicien: 'Technicien',
        statut_agent: 'Agent de maîtrise',
        statut_cadre: 'Cadre',
        vos_points: "Vos points",
        votre_palier: "Votre palier",
        votre_statut: "Votre statut",
        votre_remuneration: "Votre rémunération",
        par_h_brut: "/h. brut horaire",
        anciennement: "Anciennement : ",
        remuneration_legende: "Rémunération minimum conventionnelle. Données indicatives – document non-contractuel.",
        bon_a_savoir: "Bon à savoir",
        paye_plus: 'Si actuellement, <strong>vous gagnez moins</strong> : votre salaire doit être rattrapé sur les bases mininum affichées.',
        paye_moins: 'Si actuellement <strong>vous gagnez plus</strong>, cela ne remet pas en cause votre salaire actuel.',
        comprendre: 'Vous avez du mal à comprendre ces résultats ? Vous souhaitez être assisté pour en discuter avec votre employeur ? Nous pouvons vous aider :',
        contact_label: 'contactez-nous !',
        detail_calcul: 'Les détails du calcul',
        points: "Points",
        degres: "Degré",
        retour: "Retour à l'accueil",
        total: "Total",
        popup_title: "Avant de partir...",
        popup_content: "<p>Nous organisons une grande consultation sur l'application de la nouvelle Convention Collective de la production agricole.</p><p>Voulez-vous y participez ? Cela ne prendra que quelques secondes ! .</p>",
        non_merci: 'Non merci.',
        je_participe: 'Je participe !',
        faites_connaitre: 'Faites connaitre cette application !',
        scannez: 'Scannez le QR-Code',
        contact_title: 'Nous contacter',
        guide_title: 'Guide de classification',
        vos_contacts: 'Vos contacts FGA-CFDT : les Secrétaires Fédéraux Territoriaux (S.F.T) :',
        votre_sft: 'Votre secrétaire fédéral Térritorial (S.F.T.) :',
        copyright: 'tous droits réservés',
        prod_agri_fga_title: 'Production agricole FGA-CFDT',
        enquete_title: 'Enquete convention collective',
        help_banner: 'Compliqué de répondre ? Besoin d\'aide ?',
        ap1: 'La Fédération Générale Agroalimentaire CFDT est une organisation professionnelle et interprofessionnelle, avec un maillage territorial',
        ap2: 'Notre Fédération regroupe 3 Unions Professionnelles Fédérales.',
        ap3: 'La production agricole',
        ap4: 'La production agricole rassemble les secteurs de la polyculture-élevage, des cultures et élevages spécialisés, des métiers du cheval et des courses, de la forêt, du paysage, des travaux agricoles, des Cuma, de la pisciculture et de l’ostréiculture, etc.',
        ap5: 'Ce secteur représente environ 200 000 équivalents temps plein. La production agricole, c’est aussi 850 000 contrats saisonniers s’adressant à une population très diverse',
        ap6: 'La transformation alimentaire',
        ap7: 'Le secteur de la transformation, 450 000 salariés, regroupe l’ensemble des industries agricoles et alimentaires (IAA) et des coopératives agricoles, premier secteur exportateur français,',
        ap8: 'La transformation, c’est aussi le secteur de l’artisanat alimentaire (pâtisserie, boulangerie, boucherie et charcuterie) avec ses quelques 220 000 salariés. 171 000 salariés travaillent dans des coopératives qui collectent, transforment et commercialisent plus de la moitié de la production agricole.',
        ap9: 'Les services à l’agriculture',
        ap10: 'Les services privés',
        ap11: 'Secteur tertiaire de l’agriculture, il regroupe l’ensemble des services privés et professionnels intervenant dans l’agriculture et l’agroalimentaire.',
        ap12: 'Il s’agit du Crédit agricole (76 000 salariés), de la Mutualité sociale agricole (18 000 salariés) et de Groupama (17 000 salariés). Les organismes techniques et professionnels qui comprennent les Chambres d’agriculture, les Centres de gestion, les Instituts techniques, le Contrôle laitier, ainsi qu’une partie de l’enseignement agricole privé (Maisons familiales, lycées) rassemblent 30 000 salariés.',
        ap13: 'Le Secteur public',
        ap14: 'Le secteur public agricole se compose des fonctionnaires et des agents titulaires et contractuels de la fonction publique. Il recouvre notamment les salariés du ministère de l’ Agriculture et de la Pêche et ses services extérieurs(directions régionales de l’ agriculture et de la forêt, directions départementales, directions des services vétérinaires).',
        ap15: 'La fonction publique, c’est également l’ensemble des établissements publics sous tutelle dont par exemple les missions– recherche INRAE, politique forestière ONF, ont pour objectif de mettre en oeuvre la politique du ministère.',
        ap16: 'Nous sommes une des 15 fédérations professionnelles CFDT',
        ap17: 'Elles regroupent les syndicats de secteurs d’activité identiques ou proches. <br/>Elles négocient, défendent et revendiquent des avancées pour les salariés de leur secteur professionnel au niveau national.',
        ap18: 'Le syndicat et la section syndicale',
        ap19: 'Le syndicat',
        ap20: 'En adhérant à la CFDT, vous ralliez un syndicat qui regroupe les salariés d’ un même secteur d’ activité, sur un secteur géographique donné.Les syndicats de la CFDT peuvent être locaux, départementaux, régionaux ou nationaux.Généralement, ils se dotent d’ un bureau et d’ un conseil syndical où toutes les sections sont représentées.C’ est le lieu où se définissent les objectifs et la politique du syndicat.',
        ap21: 'La section syndicale',
        ap22: 'La section syndicale, constituée par les adhérents et les militants d’ une même entreprise, ou d’ un même établissement, est animée par le délégué syndical.La participation des adhérents est essentielle pour que militants et élus assurent bien leur rôle.La section syndicale accomplit différentes tâches: l’ information et la consultation, prioritairement des adhérents, puis des salariés, l’ élaboration des revendications, la conduite de la négociation dans l’ entreprise, la proposition d’ adhésion, la préparation des listes pour les élections professionnelles, la diffusion des arguments et des positions de la CFDT.',
      },
      {
        id: 'en',
        label: 'English',
        accueil: 'Home',
        bienvenue: 'Welcome',
        bienvenue2: 'on <em>My farm salary</em><br/><br/>The CFDT Agri-Agro tool<br/>to classify your employment in agricultural production',
        commencer: 'Let\'s get started!',
        apropos_title: 'About us',
        legal_title: 'Legal notice',
        cgu_title: 'GTU',
        intro_title: 'How does this work?',
        intro_subtitle: 'You are about to start estimating your position.<br/><strong>That’s good news!</strong>',
        intro_etape: 'Follow the app’s 3 quick steps to get your category.',
        intro_etape1: 'Let\'s look at the 5 categorization criteria.',
        intro_etape2: 'The app tells you your category and minimum hourly wage.',
        intro_etape3: 'To receive your personalized results immediately, give us your contact details.',
        valid_intro: 'Start the categorization',
        critere: 'Criterion',
        critere_subtitle: 'Evaluate the criterion',
        critere1_name: 'How technical is the job?',
        critere2_name: 'How much autonomy do you have?',
        critere3_name: 'What are your responsibilities?',
        critere4_name: 'What are your management duties?',
        critere5_name: 'What kind of job-related interactions do you have?',
        precedent: 'Previous step',
        suivant: 'Next step',
        c1l1: '<span class="red">I perform</span> <span class="green">one or more</span> <span class="blue">simple</span> tasks that require little to no experience',
        c1l2: '<span class="red">I perform</span> <span class="blue">skilled</span>, <span class="green">varied tasks that require prior experience or time to acquire the skills',
        c1l3: '<span class="red">I perform</span> <span class="blue">highly qualified</span>, <span class="green">highly variable</span> tasks that require solid experience',
        c1l4: '<span class="red">I am able to carry out</span> <span class="green">a set of <span class="blue">complex</span>, <span class="green">specialized</span> <span class="red">tasks</span> that require complete mastery ',
        c1l5: '<span class="red">I am responsible for</span> <span class="green">all <span class="red">assignments</span>, <span class="blue">including expert assignments</span>, <span class="green">in one or more specialty areas</span> ',
        c2l1: 'I follow <span class="green">precise instructions</span> or known procedures under <span class="red">frequent</span> supervision and <span class="red">control</span>',
        c2l2: 'I perform tasks defined by <span class="green">general instructions</span>, under <span class="red">intermittent or periodic</span> supervision <span class="red">and control</span>',
        c2l3: 'I adjust methods to meet defined targets or based on <span class="green">general guidelines</span> with <span class="red">subsequent control or assessment of the results or achievement of targets</span>',
        c2l4: 'I define strategies by <span class="green">direct delegation</span>, medium- or long-term assessment of the <span class="red">overall results</span> in my area ',
        c3_group1: 'Meeting standards',
        c31l1: 'The standards to be met are <span class="red">low or simple</span> and the consequences for not meeting them are <span class="red">limited</span>',
        c31l2: 'The standards to be met are <span class="red">high or complex</span> and the consequences for not meeting them are <span class="red">serious</span>',
        c31l3: '<span class="red">I am responsible for applying</span> standards and <span class="red">making sure they are met</span>',
        c3_group2: 'Economic stakes',
        c32l1: 'My contribution to the company\'s goals is mostly <span class="red">limited to satisfying productivity and quality requirements</span>',
        c32l2: 'I make an <span class="red">average</span> contribution to the company\'s results by <span class="red">optimizing resources and organizing my work/my team</span>',
        c32l3: 'I make a <span class="red">key</span> contribution to the company’s development, <span class="red">in particular by guiding its activities and identifying innovations</span>',
        c4l1: 'Possible transmission of knowledge and/or repetition of instructions. <span class="red">No special leadership responsibility</span>',
        c4l2: 'Regular <span class="red">technical and/or organizational leadership</span> of one or more teams that are <span class="blue">mostly temporary/seasonal</span>',
        c4l3: '<span class="red">Technical and/or organizational leadership</span> of one or more teams that are <span class="blue">mostly permanent</span> ',
        c4l4: '<span class="red">I supervise</span> one or more <span class="blue">production teams</span>',
        c4l5: '<span class="red">I supervise</span> <span class="blue">supervisors or managers</span> ',
        c4l6: '<span class="red">I manage</span> <span class="blue">all the employees</span> in the company or facility',
        c5l1: 'I have <span class="red">simple</span> job-related interactions that require me to understand the people I’m talking with both inside and outside the company and <span class="blue">to make myself understood</span> by them',
        c5l2: 'I have <span class="red">ordinary</span> job-related interactions that require me to <span class="blue">listen closely</span> to the people I\'m talking with, <span class="blue">rephrase</span> their requests, be persuasive, and <span class="blue">give advice</span>',
        c5l3: 'I have <span class="red">complex</span> job-related interactions that require me to <span class="blue">construct arguments</span> and explanations that are instructive <span class="blue">and motivate</span> my external or in-house conversation partners ',
        c5l4: 'I have <span class="red">decisive</span> job-related interactions for the company that require persuasion and tact: <span class="blue">I conduct negotiations and represent the company</span> to private or institutional players  ',
        coordonnees_title: 'We’re almost done…',
        coordonnees_subtitle: 'To receive your results,<br/>give us your contact details',
        voir_resultats: 'See the results',
        placeholder_nom: 'Your last name',
        placeholder_prenom: 'Your first name',
        placeholder_email: 'Your email address',
        placeholder_departement: 'Your department',
        rechercher: 'Search',
        contact: 'I would like the FGA-CFDT to contact me about my job',
        placeholder_tel: 'Your telephone number',
        rgpd: 'By checking this box, you agree that we may process the personal data you provided above, in accordance with our  <a href="https://www.echanges-fga-cfdt.fr/politique-de-confidentialite-2/" target="_blank">privacy policy</a>. The FGA-CFDT processes your personal data so it can help you by processing and following up on your request. You have various rights over your data, including the rights to data portability, to access, correct, or erase your data, to restrict or object to the processing of your data, and to withdraw your consent to processing. You may exercise these rights by sending your request via <a href="https://www.echanges-fga-cfdt.fr/fcrm-form/rgpd-faire-valoir-vos-droits-sur-vos-donnees-personnelles/" target="_blank">our GDPR form</a>.',
        resultats_title: 'Here are your results!',
        resultats_subtitle: 'We also sent them to you by email!',
        default_statut: 'Employee',
        statut_technicien: 'Technician',
        statut_agent: 'Supervisor',
        statut_cadre: 'Manager',
        vos_points: 'Your points',
        votre_palier: 'Your level',
        votre_statut: 'Your status',
        votre_remuneration: 'Your pay',
        par_h_brut: 'Gross hourly wage',
        anciennement: '',
        remuneration_legende: 'Rémunération minimum conventionnelle. Données indicatives – document non-contractuel.',
        bon_a_savoir: 'Good to know',
        paye_plus: 'If you currently <strong>earn less</strong>, your salary has to be increased based on the displayed minimums.',
        paye_moins: 'If you currently <strong>earn more</strong>, your salary cannot be challenged on this basis.',
        comprendre: 'Are you having trouble understanding these results? Would you like help to discuss them with your employer? We can help!',
        contact_label: 'contact us!',
        detail_calcul: 'Calculation details',
        points: 'Points',
        degres: 'Degree',
        retour: 'Back to the home page',
        total: 'Total',
        popup_title: 'Before you go…',
        popup_content: '<p>We are conducting a survey on the new agricultural production collective bargaining application. </p><p>Would you like to take it? It will only take a few seconds!</p>',
        non_merci: 'No thank you.',
        je_participe: 'Yes, I’ll take the survey.',
        faites_connaitre: 'Tell your friends about this application!',
        scannez: 'Scan the QR-Code',
        contact_title: 'Contact us',
        guide_title: 'Categorization guide',
        vos_contacts: 'Your FGA-CFDT contacts: the Federal Territorial Secretaries (SFTs):',
        votre_sft: 'Your federal territorial secretary (SFT):',
        copyright: 'All rights reserved',
        prod_agri_fga_title: 'FGA-CFDT agricultural production',
        enquete_title: 'Collective bargaining survey',
        help_banner: 'Complicated to answer? Need help ?',
        ap1: 'The General Federation of Agri-Food CFDT is a professional and interprofessional organisation, with a territorial network',
        ap2: 'Our Federation is made up of 3 Federal Professional Unions.',
        ap3: 'Agricultural production',
        ap4: 'Agricultural production includes the sectors of polyculture-breeding, specialized crops and livestock, horse and racing trades, forest, landscape, agricultural work, Cuma, fish farming and oyster farming, etc.',
        ap5: 'This sector represents about 200,000 full-time equivalents. Agricultural production is also 850,000 seasonal contracts for a very diverse population',
        ap6: 'Food processing',
        ap7: 'The processing sector, employing 450,000 people, comprises all agricultural and food industries (IAA) and agricultural cooperatives, France\'s largest export sector,',
        ap8: 'The transformation is also the food crafts sector (pastry, bakery, butcher and deli) with some 220,000 employees. 171,000 employees work in cooperatives that collect, process and market more than half of agricultural production.',
        ap9: 'Agriculture services',
        ap10: 'Private services',
        ap11: 'The tertiary sector of agriculture, it brings together all private and professional services involved in agriculture and agri-food.',
        ap12: 'These include The Agricultural Credit (76,000 employees), the Agricultural Social Mutuality (18,000 employees) and Groupama (17,000 employees). Technical and professional bodies that include the Chambers of Agriculture, Management Centres, Technical Institutes, Dairy Control, as well as part of private agricultural education (Family Homes, High Schools) have 30,000 employees.',
        ap13: 'The Public Sector',
        ap14: 'The agricultural public sector consists of public servants and incumbent and contract officials in the public service. It covers, among other things, employees of the Ministry of Agriculture and Fisheries and its external services (regional directorates of agriculture and forestry, departmental directorates, directorates of veterinary services).',
        ap15: 'The public service is also the public institution under guardianship, for example, whose missions - INRAE research, NFB forestry policy - aim to implement the ministry\'s policy.',
        ap16: 'We are one of 15 CFDT professional federations',
        ap17: 'They bring together trade unions from identical or similar sectors of activity. They negotiate, defend and demand progress for employees in their professional sector at the national level.',
        ap18: 'The union and the trade union section',
        ap19: 'The union',
        ap20: 'By joining the CFDT, you are joining a union that brings together employees from the same industry, in a given geographical area. CFDT unions may be local, departmental, regional or national. Generally, they have an office and a union council where all sections are represented. This is the place where the union\'s objectives and policy are defined.',
        ap21: 'The trade union section',
        ap22: 'The trade union section, formed by members and activists of the same company, or establishment, is led by the trade union delegate. The participation of members is essential for activists and elected officials to ensure their role. The trade union section performs various tasks: information and consultation, primarily of members, then employees, the development of demands, the conduct of negotiation in the company, the proposal for membership, the preparation of lists for professional elections, the dissemination of arguments and positions of the CFDT.',
      },
      {
        id: 'es',
        label: 'Español',
        accueil: 'Inicio ',
        bienvenue: 'Bienvenida',
        bienvenue2: 'sobre <em>Mi salario agrícola</em><br/><br/>La herramienta CFDT Agri-Agro<br/>para clasificar su empleo en producción agrícola',
        commencer: 'Empezar',
        apropos_title: 'Quiénes somos',
        legal_title: 'Menciones legales',
        cgu_title: 'Términos de servicio',
        intro_title: '¿Cómo funciona?',
        intro_subtitle: '<strong>¡Buena noticia!</strong><br/>Estás a punto de iniciar la estimación de tu puesto ',
        intro_etape: 'Sigue las tres etapas rápidas de la aplicación para obtener tu clasificación ',
        intro_etape1: 'Evalúa los 5 criterios de clasificación ',
        intro_etape2: 'La aplicación da tu clasificación y tu remuneración horaria mínima',
        intro_etape3: 'Indica tu señas para recibir  inmediatamente tu resultado personalizado',
        valid_intro: 'Inicia tu clasificación',
        critere: 'Criterio',
        critere_subtitle: 'Evalúa el criterio',
        critere1_name: 'Tecnicidad del puesto',
        critere2_name: 'Autonomía del puesto',
        critere3_name: 'Responsabilidad del puesto',
        critere4_name: 'Management del puesto',
        critere5_name: 'Relaciones del puesto',
        precedent: 'Etapa anterior',
        suivant: 'Etapa siguiente',
        c1l1: '<span class="red">Ejecución</span> <span class="green">de una o varias tareas</span> <span class="blue">simples</span>, que requieren poca o ninguna experiencia',
        c1l2: '<span class="red">Realización de operaciones</span> <span class="blue">cualificadas</span>, <span class="green">variadas</span>, que requieren experiencia previa o un tiempo de apropiación ',
        c1l3: '<span class="red">Reealización de actividades</span> <span class="blue">muy cualificadas</span>, <span class="green">altamente evolutivas</span> que requieren un dominio confirmado del oficio',
        c1l4: '<span class="red">Capacidad para ejecutar</span> <span class="green">todas <span class="red">las actividades</span> <span class="blue">complejas</span> <span class="green">de un ámbito de especialización</span> que requirien un dominio completo  ',
        c1l5: '<span class="red">Responsabilidad del conjunto de las misiones</span>, <span class="blue">incluyendo especializadas</span> <span class="green">de uno o varios ámbitos de especialización</span> ',
        c2l1: 'Aplicación de <span class="green">consignas precisas</span> o de procedimientos conocidos, bajo vigilancia y <span class="red">control frecuentes</span> ',
        c2l2: 'Actividades definidas por <span class="green">instrucciones generales</span>, bajo vigilancia y <span class="red">control puntuales o periódicos</span>',
        c2l3: 'Adaptación de los métodos en el marco de objetivos definidos o a partir de <span class="green">directivas generales</span>, control o valoración <span class="red">a posteriori de los resultados o del alcance de los objetivos</span> ',
        c2l4: 'Definición de estrategias por <span class="green">delegación directa</span>, evaluación a medio o largo plazo de los <span class="red">resultados globales</span> de su área de responsabilidad',
        c3_group1: 'Respeto de las normas',
        c31l1: 'Exigencias normativas <span class="red">bajas o simples</span> y consecuencias <span class="red">limitadas</span> en caso de no respetarse ',
        c31l2: 'Exigencias normativas <span class="red">altas o complejas</span> y consecuencias <span class="red">importantes</span> en caso de no respetarse ',
        c31l3: '<span class="red">Responsabilidad del despliegue</span> de las normas y de la <span class="red">verificación de su respeto</span> ',
        c3_group2: 'Implicaciones económicas',
        c32l1: 'Contribución <span class="red">limitada</span> a los objetivos de la actividad, obtenida especialmente por el <span class="red">respeto de las obligaciones de productividad y de calidad</span>',
        c32l2: 'Contribución <span class="red">moderada</span> al resultado de la empresa especialmente mediante <span class="red">la optimización de los medios, de la organización del empleo/ del equipo</span> ',
        c32l3: 'Contribución <span class="red">esencial</span> al desarrollo de la empresa, especialmente <span class="red">por orientación de las actividades e identificación de innovaciones</span> ',
        c4l1: 'Transmisión de competencias posible y/o recordar una consigna <span class="red">Sin responsabilidad específica de animación.</span>',
        c4l2: '<span class="red">Animación técnica y/u organizativa</span> periódica de uno u varios equipos, <span class="blue">mayoritariamente temporales/estacionales</span>',
        c4l3: '<span class="red">Animación técnica y/u organizativa</span> de uno o varios equipos <span class="blue">mayoritariamente permanentes</span> ',
        c4l4: '<span class="red">Marco jerárquico de proximidad</span> (de un o varios <span class="blue">equipos de ejecución)</span> ',
        c4l5: '<span class="red">Marco jerárquico</span> <span class="blue">de responsables o directivos</span>  ',
        c4l6: '<span class="red">Responsabilidad jerárquica</span> <span class="blue">de todos los empleados</span> de la empresa o del establecimiento ',
        c5l1: 'Interacciones profesionales <span class="red">simples</span>, que requieren una buena comprensión de sus interlocutores internos o externos y <span class="blue">hacerse entender</span> por ellos',
        c5l2: 'Interacciones profesionales <span class="red">corrientes</span>, que requieren <span class="blue">una escucha atenta</span> de sus interlocutores, <span class="blue">la reformulación</span> de sus solicitudes,  fuerza de convicción y <span class="blue">asesoramiento</span>  ',
        c5l3: 'Interacciones profesionales <span class="red">complejas</span>, que requieren <span class="blue">la construcción de argumentarios y explicaciones pedagógicas, capacidad <span class="blue">para movilizar a sus interlocutores externos o internos ',
        c5l4: 'Relaciones profesionales <span class="red">determinantes</span> para la empresa, que requieren persuasión y diplomacia, <span class="blue">negociación y representación de la empresa</span> ante actores privados o institucionales ',
        coordonnees_title: 'Ya estás casi...',
        coordonnees_subtitle: 'Para recibir tu resultados, <br/>introduce tus señas ',
        voir_resultats: 'Ver resultados',
        placeholder_nom: 'Apellido',
        placeholder_prenom: 'Nombre',
        placeholder_email: 'Dirección de correo electrónico',
        placeholder_departement: 'Selecciona tu provincia',
        rechercher: 'Buscar…',
        contact: 'Deseo que me contacte la FGA-CFDT para asesorarme sobre mi puesto',
        placeholder_tel: 'Teléfono',
        rgpd: 'Al marcar esta casilla, aceptas que los datos personales introducidos sean tratados por nuestros servicios de conformidad con nuestra  <a href="https://www.echanges-fga-cfdt.fr/politique-de-confidentialite-2/" target="_blank">política de confidencialidad</a>. La FGA-CFDT usa tus datos personales para poder ayudarte mendiante el tratamiento y seguimiento de tu solicitud. Dispones de derechos sobre tus datos personales, como el derecho a la portabilidad, de acceso, de rectificación, de eliminación, de limitación, de oposición y de retirada del consentimiento. Puedes ejercer estos derechos enviando tu solicitud por medio de <a href="https://www.echanges-fga-cfdt.fr/fcrm-form/rgpd-faire-valoir-vos-droits-sur-vos-donnees-personnelles/" target="_blank">nuestro formulario RGPD</a>.',
        resultats_title: '¡Tus resultados!',
        resultats_subtitle: 'Te los enviamos también por correo electrónico',
        default_statut: 'Empleado',
        statut_technicien: 'Técnico',
        statut_agent: 'Supervisor',
        statut_cadre: 'Directivo',
        vos_points: 'Puntos',
        votre_palier: 'Nivel',
        votre_statut: 'Estatus',
        votre_remuneration: 'Remuneración',
        par_h_brut: '/h. bruto horario',
        anciennement: '',
        remuneration_legende: 'Rémunération minimum conventionnelle. Données indicatives – document non-contractuel.',
        bon_a_savoir: '¿Sabías que?',
        paye_plus: 'Si actualmente <strong>ganas menos</strong>: tu salario debe ajustarse en base a los mínimos presentados ',
        paye_moins: 'Si actualmente <strong>ganas más</strong>, esto no se cuestiona tu salario actual.',
        comprendre: '¿Te cuesta entender estos resultados? ¿Necesitas asistencia para hablarlo con tu empleador? Te podemos ayudar: ',
        contact_label : 'Contáctanos',
        detail_calcul: 'Detalles del cálculo',
        points: 'Puntos',
        degres: 'Grado',
        retour: 'Volver al inicio',
        total: 'Total',
        popup_title: 'Antes de salir… ',
        popup_content: '<p>Organizamos una gran consulta sobre la aplicación del nuevo Convenio Colectivo de la producción agrícola.</p><p>¿Quieres participar? ¡Serán solo unos segundos!</p>',
        non_merci: 'No, gracias.',
        je_participe: 'Participar',
        faites_connaitre: '¡Da a conocer esta aplicación!',
        scannez: 'Escanea el código QR',
        contact_title: 'Contáctarnos',
        guide_title: 'Guía de clasificación',
        vos_contacts: 'Tus contactos FGA-CFDT: los Secretarios Federales Territoriales (S.F.T.):',
        votre_sft: 'Tu secretario federal territorial (S.F.T.):',
        copyright: 'Todos los derechos reservados',
        prod_agri_fga_title: 'Producción agrícola FGA-CFDT',
        enquete_title: 'Encuesta convenio colectivo',
        help_banner: '¿Complicado responder? Necesidad de ayuda ?',
        ap1: 'La Federación General agroalimentaria CFDT es una organización profesional e interprofesional, con una red territorial',
        ap2: 'Nuestra Federación está formada por 3 Sindicatos Profesionales Federales.',
        ap3: 'Producción agrícola',
        ap4: 'La producción agrícola incluye los sectores de policultivo-cría, cultivos especializados y ganadería, comercios de caballos y carreras, bosques, paisaje, trabajo agrícola, Cuma, piscicultura y ostras, etc.',
        ap5: 'Este sector representa unos 200.000 equivalentes a tiempo completo. La producción agrícola es también de 850.000 contratos estacionales para una población muy diversa',
        ap6: 'Procesamiento de alimentos',
        ap7: 'El sector de la transformación, que emplea a 450.000 personas, comprende todas las industrias agrícolas y alimentarias (AIEA) y las cooperativas agrícolas, el sector exportador más grande de Francia,',
        ap8: 'La transformación es también el sector de la artesanía alimentaria (pastelería, panadería, carnicería y delicatessen) con unos 220.000 empleados. 171.000 empleados trabajan en cooperativas que recogen, procesan y comercializan más de la mitad de la producción agrícola.',
        ap9: 'Servicios agrícolas',
        ap10: 'Servicios privados',
        ap11: 'El sector terciario de la agricultura, reúne todos los servicios privados y profesionales involucrados en la agricultura y la agroalimentación.',
        ap12: 'Se trata de The Agricultural Credit (76.000 empleados), la Mutualidad Social Agraria (18.000 empleados) y Groupama (17.000 empleados). Los organismos técnicos y profesionales que incluyen las Cámaras de Agricultura, Centros de Gestión, Institutos Técnicos, Control Lácteo, así como parte de la educación agrícola privada (Hogares Familiares, Escuelas Secundarias) tienen 30.000 empleados.',
        ap13: 'El sector público',
        ap14: 'El sector público agrícola está formado por servidores públicos y funcionarios titulares y contratados en la función pública. Abarca, entre otras cosas, a los empleados del Ministerio de Agricultura y Pesca y sus servicios externos (direcciones regionales de agricultura y silvicultura, direcciones departamentales, direcciones de servicios veterinarios).',
        ap15: 'El servicio público es también la institución pública bajo tutela, por ejemplo, cuyas misiones -investigación del INRAE, política forestal de la NFB- tienen como objetivo implementar la política del ministerio.',
        ap16: 'Somos una de las 15 federaciones profesionales de CFDT',
        ap17: 'Reúnen a sindicatos de sectores de actividad idénticos o similares. Negocian, defienden y exigen avances para los empleados de su sector profesional a nivel nacional.',
        ap18: 'El sindicato y la sección sindical',
        ap19: 'El sindicato',
        ap20: 'Al unirse a la CFDT, se está uniendo a un sindicato que reúne a empleados de la misma industria, en un área geográfica determinada. Los sindicatos CFDT pueden ser locales, departamentales, regionales o nacionales. Por lo general, tienen una oficina y un consejo sindical donde están representadas todas las secciones. Este es el lugar donde se definen los objetivos y la política del sindicato.',
        ap21: 'La sección sindical',
        ap22: 'La sección sindical, formada por miembros y activistas de la misma empresa, o establecimiento, está dirigida por el delegado sindical. La participación de los miembros es esencial para que los activistas y funcionarios electos garanticen su papel. La sección sindical realiza diversas tareas: información y consulta, principalmente de miembros, luego empleados, desarrollo de demandas, realización de negociación en la empresa, propuesta de afiliación, elaboración de listas para elecciones profesionales, difusión de argumentos y cargos de la CFDT.',
      },
    ],
    critere1: false,
    critere2: false,
    critere31: false,
    critere32: false,
    critere4: false,
    critere5: false,
    critere1_points: [2, 4, 16, 38, 100],
    critere2_points: [2, 7, 28, 100],
    critere31_points: [1, 4, 25],
    critere32_points: [1, 4, 25],
    critere4_points: [2, 3, 10, 23, 48, 100],
    critere5_points: [1, 4, 14, 50],
    palliers: [11, 16, 24, 35, 51, 73, 104, 143, 196, 270, 399],
    old_remuneration: [10.15, 10.23, 10.38, 10.60, 11.10, 11.65, 12.35, 13.24, 14.35, 15.90, 18.10, 20.70],
    //new_remuneration: [10.25, 10.33, 10.48, 10.71, 11.21, 11.77, 12.47, 13.37, 14.49, 16.06, 18.28, 20.91],
    //new_remuneration: [10.57, 10.57, 10.71, 10.95, 11.46, 12.03, 12.78, 13.70, 14.85, 16.46, 18.74, 21.43],
    //new_remuneration: [11.07, 11.07, 11.11, 11.35, 11.88, 12.47, 13.21, 14.16, 15.34, 17.02, 19.36, 22.15],
    //new_remuneration: [11.27, 11.36, 11.53, 11.77, 12.29, 12.87, 13.62, 14.56, 15.76, 17.46, 19.86, 22.70],
    new_remuneration: [11.65, 11.74, 11.91, 11.17, 12.70, 13.30, 14.08, 15.05, 16.29, 18.04, 20.53, 23.46],
    departements: departements,
    nom: '',
    prenom: '',
    departement: false,
    email: '',
    tel: ''
  },
  getters: {},
  mutations: {
    setCritere1(state, payload) {
      state.critere1 = payload.critere1;
    },
    setCritere2(state, payload) {
      state.critere2 = payload.critere2;
    },
    setCritere31(state, payload) {
      state.critere31 = payload.critere31;
    },
    setCritere32(state, payload) {
      state.critere32 = payload.critere32;
    },
    setCritere4(state, payload) {
      state.critere4 = payload.critere4;
    },
    setCritere5(state, payload) {
      state.critere5 = payload.critere5;
    },
    setNom(state, payload) {
      state.nom = payload.nom;
    },
    setPrenom(state, payload) {
      state.prenom = payload.prenom;
    },
    setDepartement(state, payload) {
      state.departement = payload.departement;
    },
    setEmail(state, payload) {
      state.email = payload.email;
    },
    setTel(state, payload) {
      state.tel = payload.tel;
    },
    eraseCurrentResults(state) {
      state.critere1 = false;
      state.critere2 = false;
      state.critere31 = false;
      state.critere32 = false;
      state.critere4 = false;
      state.critere5 = false;

      state.nom = '';
      state.prenom = '';
      state.departement = false;
      state.email = '';
      state.tel = '';
    }
  },
  actions: {},
  modules: {},
});